import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown/with-html';
import SEO from '../components/seo';
import Wrapper from '../containers/wrapper';
import { buildImageObj, imageUrlFor } from '../components/sanity/image-url';

export const query = graphql`
  query BasicTemplateQuery($id: String!) {
    basic: sanityBasic(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      image {
        asset {
          _id
        }
      }
      hideTitle
      widePage
      body
    }
  }
`;

const BasicPage = props => {
  const { data } = props;
  const page = _.get(data, 'basic');

  if (!page) {
    throw new Error('Missing data');
  }

  return (
    <Wrapper>
      <SEO title={page.title} />
      <div className="container">
        <div className="row justify-content-start">
          <WrapDiv condition={!page.widePage}>
            {!page.hideTitle && (
              <header className="page-heading">
                <h1 className="page-heading__title">{page.title}</h1>
              </header>
            )}

            {page.image && page.image.asset && (
              <div className="col-12 mb-4">
                <img src={imageUrlFor(buildImageObj(page.image)).width(1200)} />
              </div>
            )}

            {page.body && <ReactMarkdown source={page.body} />}
          </WrapDiv>
        </div>
      </div>
    </Wrapper>
  );
};

function WrapDiv({ condition, children }) {
  if (!condition) {
    return children;
  }
  return <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">{children}</div>;
}

export default BasicPage;
